<template>
    <div class="gameContainer" :style="'width: ' + 90/amount + '%;background: ' + color + ';'" @click="clickanim">
        <div style="height: 100%; width: 50%; z-index:11;" :style="'background:'+color"></div>
        <img :src="logo" class="logo" style="z-index: 12">
        <div class="number" style="z-index:12">
            <svg xmlns="http://www.w3.org/2000/svg" width="95" height="91" viewBox="0 0 95 91" style="transform:scale(1.4)">
            <path id="Polygon_2" data-name="Polygon 2" d="M37.364,9.292a15,15,0,0,1,20.272,0l2.647,2.426a15,15,0,0,0,7.979,3.787l3.426.5a15,15,0,0,1,12.8,16.027l-.233,2.951A15,15,0,0,0,86.293,43.8l1.469,2.485a15,15,0,0,1-4.6,20.12l-2.59,1.724a15,15,0,0,0-5.631,6.96l-1.206,3.044A15,15,0,0,1,55.4,86.949L51.89,85.874a15,15,0,0,0-8.779,0L39.6,86.949a15,15,0,0,1-18.335-8.818l-1.206-3.044a15,15,0,0,0-5.631-6.96L11.835,66.4a15,15,0,0,1-4.6-20.12L8.707,43.8a15,15,0,0,0,2.042-8.818l-.233-2.951A15,15,0,0,1,23.312,16l3.426-.5a15,15,0,0,0,7.979-3.787Z" fill="#B25555"/>
            </svg>
            <div  style="position:absolute;left:50%;transform:translate(-50%,-40%);top:50%">
            {{number+1}}
            </div>
        </div>
        <div class="backButton" @click="goBack" v-show="selected">Ga terug</div>
        <level-button v-for="(level, index) in levels" :key="level.name" :type="type" :name="level.name" :bgImg="level.logo" :bgColor="level.color" :active="selected" :amount="levels.length" :index="index" :cards="level.cards" @goBack="goBack"></level-button>
        <div class="gameText" style="z-index:12">{{text}}</div>
    </div>
</template>

<script>
import LevelButton from './LevelButton.vue';

export default {
    name: "GameBlock",
    props:["text", "color", "amount", "number", "logo", "levels", "type"],
    components:{
        LevelButton
    },
    data(){
        return {
            status: 0,
            selected: false
        }
    },
    methods:{
        clickanim(){
            var elem = document.getElementsByClassName("gameContainer")[this.number];
            if(this.status == 0){
                if(this.number == 0){
                    elem.style.left = "32.5%";
                }
                if(this.number == 2){
                    elem.style.left = "-32.5%";
                }
                for(let i = 0; i < this.amount; i++){
                    if(i !== this.number){
                        document.getElementsByClassName("gameContainer")[i].style.opacity = "0";
                    }
                }
                elem.style.zIndex = "99999";
                setTimeout(function(){
                    elem.lastChild.style.opacity = 0;
                    setTimeout(function(){
                        elem.style.height = "70%";
                        setTimeout(function(){
                            elem.style.top = "-30%";
                            setTimeout(function(){
                                setTimeout(function(){
                                    this.selected = true;
                                    for(let i = 0; i < this.amount; i++){
                                        if(i !== this.number){
                                            document.getElementsByClassName("gameContainer")[i].style.visibility = "hidden";
                                        }
                                    }
                                }.bind(this), 200);
                            }.bind(this), 500);
                        }.bind(this), 500);
                    }.bind(this), 500);
                }.bind(this), 500);
                this.status = 1;
            }
        },
        goBack(){
            this.selected = false;
            for(let i = 0; i < this.amount; i++){
                
                var elem = document.getElementsByClassName("gameContainer")[i];
                elem.style.left = "0";
                elem.style.zIndex = "1";
                elem.style.height = "100%";
                elem.style.top = "0";
                document.getElementsByClassName("gameContainer")[i].style.opacity = "1";
                document.getElementsByClassName("gameContainer")[i].style.visibility = "visible";
                elem.lastChild.style.opacity = 1;
              
            }
            setTimeout(function(){
                this.status = 0
            }.bind(this), 500);
        }
    }
}
</script>

<style scoped>
.gameContainer{
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 20px;
    transition: 0.3s all ease;
    left:0;
    right: 0;
    top:0;
    z-index: 1;
}
.logo{
    position: absolute;
    left:50%;
    transform: translateX(-50%);
    top:-40px;
}
.gameText{
    color:white;
    font-weight: 700;
    font-size: 22px;
    width: 50%;
    text-align: center;
    position: absolute;
    bottom:20px;
    left:50%;
    transform: translateX(-50%);
    height: 20%;
    transition: all 0.3s ease;
}
.number{
    position:absolute;
    left:50%;
    top:-50px;
    transform:translateX(-50%);
    font-size:40px;
    color:white;
    z-index: 99;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.backButton{
    position: fixed;
    left: 20px;
    bottom: 20px;
    background: #7accdb;
    color:white;
    padding: 20px;
    font-size: 30px;
    border-radius: 30px;
}
</style>