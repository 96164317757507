<template>
    <div class="imageCardContainer">
        <div class="imageContainer">
            <canvas width="1920" height="1080" class="canvas" ref="canvas"></canvas>
            <img :src="cards.img" alt="" class="colorImg">
            <div class="canvasTouch" @mousedown="move = true" @mouseup="move = false" @mousemove="clickCanvas" @touchstart="move = true" @touchmove="clickCanvas" @touchend="move= false"></div>
        </div>
        <div class="controls">
            <h2>Kleuren</h2>
            <div class="colorCon">
                <div v-for="color in colors" :key="color" class="color" :style="curCol == color?'background:'+color+';transform:scale(1.2);border:4px solid white;':'background:'+color" @click="curCol = color;status = 1"></div>
            </div>
            <div class="removeControls">
                <div class="erasor" @click="curCol = '#EEEFE7';status = 0" :style="status == 0? 'transform: scale(1.2)':''">
                    <svg id="Group_170" data-name="Group 170" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="243.149" height="197.133" viewBox="0 0 243.149 197.133" >
                        <defs>
                            <clipPath id="clip-path">
                            <rect id="Rectangle_104" data-name="Rectangle 104" width="243.149" height="197.133" fill="none"/>
                            </clipPath>
                        </defs>
                        <g id="Group_169" data-name="Group 169" clip-path="url(#clip-path)">
                            <path id="Path_1305" data-name="Path 1305" d="M45.943,194.335,8.535,172.14A16.4,16.4,0,0,1,2.8,149.666l15.153-25.54a16.4,16.4,0,0,1,22.475-5.737l37.408,22.195a16.4,16.4,0,0,1,5.737,22.474L68.418,188.6a16.4,16.4,0,0,1-22.475,5.737" fill="#fff"/>
                            <path id="Path_1306" data-name="Path 1306" d="M45.943,194.335,8.535,172.14A16.4,16.4,0,0,1,2.8,149.666l15.153-25.54a16.4,16.4,0,0,1,22.475-5.737l37.408,22.195a16.4,16.4,0,0,1,5.737,22.474L68.418,188.6A16.4,16.4,0,0,1,45.943,194.335Z" fill="none" stroke="#333" stroke-miterlimit="10" stroke-width="1"/>
                            <path id="Path_1307" data-name="Path 1307" d="M45.943,194.335,8.535,172.14A16.4,16.4,0,0,1,2.8,149.666L68.418,188.6a16.4,16.4,0,0,1-22.475,5.737" fill="#2D2D2D"/>
                            <path id="Path_1308" data-name="Path 1308" d="M83.795,160.4l-61.452-36.46a15.519,15.519,0,0,1-5.429-21.266L73.022,8.1A15.52,15.52,0,0,1,94.289,2.675l61.452,36.46A15.518,15.518,0,0,1,161.169,60.4L105.06,154.97A15.519,15.519,0,0,1,83.795,160.4" fill="#fff"/>
                            <path id="Path_1309" data-name="Path 1309" d="M83.795,160.4l-61.452-36.46a15.519,15.519,0,0,1-5.429-21.266L73.022,8.1A15.52,15.52,0,0,1,94.289,2.675l61.452,36.46A15.518,15.518,0,0,1,161.169,60.4L105.06,154.97A15.519,15.519,0,0,1,83.795,160.4Z" fill="none" stroke="#282828" stroke-miterlimit="10" stroke-width="1"/>
                            <path id="Path_1310" data-name="Path 1310" d="M240.967,196.633H53.331a2.182,2.182,0,0,1-2.182-2.182v-4.636a2.182,2.182,0,0,1,2.182-2.182H240.967a2.182,2.182,0,0,1,2.182,2.182v4.636a2.182,2.182,0,0,1-2.182,2.182" fill="#2D2D2D"/>
                        </g>
                    </svg>
                </div>
                <div class="pencil" @click="curCol = colors[0];status = 1" :style="status == 1? 'transform: scale(1.2)':''">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="138.405" height="228.531" viewBox="0 0 138.405 228.531">
                        <defs>
                            <clipPath id="clip-path">
                            <rect id="Rectangle_102" data-name="Rectangle 102" width="96.182" height="213.192" fill="none"/>
                            </clipPath>
                        </defs>
                        <g id="Group_171" data-name="Group 171" transform="matrix(0.978, 0.208, -0.208, 0.978, 44.325, 0)">
                            <g id="Group_167" data-name="Group 167" transform="translate(0 0)" clip-path="url(#clip-path)">
                            <path id="Path_1301" data-name="Path 1301" d="M52.246,156.275c2.886-8.506,5.588-17.394,8.044-26.662A420.115,420.115,0,0,0,73.447,0,420.153,420.153,0,0,0,17.155,117.488c-2.733,9.191-5.056,18.188-7.025,26.952Z" transform="translate(22.142 -0.001)" fill="#F0F0F0"/>
                            <path id="Path_1302" data-name="Path 1302" d="M10.31,45.562c-1.829,14.865,3.772,27.92,14.037,30.806s21.848-5.343,28.032-18.984Z" transform="translate(21.786 99.587)" fill="#8D8D8D"/>
                            <path id="Path_1303" data-name="Path 1303" d="M61.164,68.688c1.937,5.871.465,12.667-2.756,17.413C49.94,98.6,26.557,101.091,0,92.158c10.762-4.314,22.53-11.287,25.652-22.75,1.845-6.767-.4-11.157,2.912-14.161,6.591-5.992,28.15-.057,32.6,13.441" transform="translate(0 115.659)"/>
                            <path id="Path_1304" data-name="Path 1304" d="M22.558,60.211a7.852,7.852,0,0,1,1.953,1.125c2.791,2.189,2.119,4.791,4.1,6.333,2.23,1.727,4.228-.653,7.222.959,2.979,1.606,2.119,4.562,4.833,5.639,2.577,1.019,4.454-1.207,7.5-.032a6.881,6.881,0,0,1,3.039,2.447,52.563,52.563,0,0,1-13.406,4.2C29.064,82.234,16.263,82.12,0,76.21c.357.634,20.1-9.605,22.558-16" transform="translate(0 131.607)" fill="#3955A4"/>
                            </g>
                        </g>
                    </svg>
                </div>
                <div class="restart" @click="clearCanvas">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="246.791" height="254.359" viewBox="0 0 246.791 254.359">
                        <defs>
                            <clipPath id="clip-path">
                            <rect id="Rectangle_101" data-name="Rectangle 101" width="211.636" height="220.966" fill="none"/>
                            </clipPath>
                        </defs>
                        <g id="Group_172" data-name="Group 172" transform="translate(0 36.75) rotate(-10)">
                            <g id="Group_163" data-name="Group 163" transform="translate(0 0.001)" clip-path="url(#clip-path)">
                            <path id="Path_1298" data-name="Path 1298" d="M14.533,66.623c32.249,21.512,79.276,34.526,146.912-23.779" transform="translate(34.278 101.05)" fill="none" stroke="#F2F2F2" stroke-miterlimit="10" stroke-width="3"/>
                            <path id="Path_1299" data-name="Path 1299" d="M0,39.134c16.82,25.465,34.772,61.049,41.2,89.53l14.311-48.9L97.792,51.332C68.7,53.821,29.354,47.473,0,39.134" transform="translate(0 92.3)" fill="#F2F2F2"/>
                            <path id="Path_1300" data-name="Path 1300" d="M110.978,0c48.811,28.122,87.055,94.155,57.986,149.532-14.3,28.545-42.48,52.058-75.246,53.063-30.248,1.787-61.949-11.755-79.9-35.92l1.9-2.771c24.3,6.267,46,9.911,69.916,6.173,54.688-9.082,83.4-48.062,66.615-102.686-7.439-24.357-23.423-46.54-43.276-64.7Z" transform="translate(32.589 -0.002)" fill="#F2F2F2"/>
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
            
            <div class="sizeClass">
                <div class="size">
                    <div class="sizeBall">
                        <div class="small" :style="'background:'+curCol" :class="size==10?'border':''" @click="size = 10;csize = 20"></div>
                    </div>
                    Klein
                </div>
                <div class="size">
                    <div class="sizeBall">
                        <div class="medium" :style="'background:'+curCol" :class="size==20?'border':''" @click="size = 20;csize = 40"></div>
                    </div>
                    Normaal
                </div>
                <div class="size">
                    <div class="sizeBall">
                        <div class="large" :style="'background:'+curCol" :class="size==40?'border':''" @click="size = 40;csize = 80"></div>
                    </div>
                    Groot
                </div>
            </div>
        </div>
        <div ref="mousecircle" class="mouseCircle" :style="'width: '+csize+'px;height:'+csize+'px;background:'+curCol+';left:'+leftPos+'px;top:'+topPos+'px'"></div>
    </div>
</template>

<script>
export default {
    name: 'ColorGame',
    props: ["cards"],
    data(){
        return{
            colors: [
                "#60883D",
                "#6C7F67",
                "#008086",
                "#005C74",
                "#1D2C65",
                "#A3C0E2",
                "#F3B0A4",
                "#EE807C",
                "#E32427",
                "#C61269",
                "#F6B137",
                "#BE8F67",
                "#EEEEEE",
                "#000000",
                "#555555",
            ],
            curCol: "#60883D",
            move: false,
            size: 20,
            leftPos: -100,
            topPos: -100,
            csize: 40,
            status: 1,
        }
    },
    methods:{
        colorPick(c){
            console.log(c)
        },
        clickCanvas(event){
            console.log(event)
            if(event.type == "mousemove"){
            var posX = event.pageX;
            var posY = event.pageY;
            this.topPos = posY-(this.csize/2);
            this.leftPos = posX-(this.csize/2);
            }else{
            var posX = event.touches[0].clientX;
            var posY = event.touches[0].clientY;
            }
            if(this.move){
                var c = this.$refs.canvas;
                var ctx = c.getContext("2d");
                ctx.beginPath();
                ctx.arc(posX, posY, this.size, 0, 2 * Math.PI);
                ctx.lineWidth = 0;
                ctx.fillStyle = this.curCol;
                ctx.fill();
            }
        },
        clearCanvas(){
            var c = this.$refs.canvas;
            var ctx = c.getContext("2d");
            ctx.clearRect(0, 0, c.width, c.height);
        }
    }
}
</script>

<style scoped>
.imageCardContainer{
    position: relative;
}
.canvas{
    position: absolute;
    width: 100%;
    height: 100%;
}
.imageContainer{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px;
}
.colorImg{
    object-fit: contain;
    object-position: center;
    height: 100%;
    z-index: 9;
}
.controls{
    position: absolute;
    width: 20%;
    background-color: #ce807c;
    height: 90%;
    right:5%;
    border-radius: 30px;
    overflow: hidden;
    padding: 5px;
    box-shadow: 0 2px 10px 0 grey;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 999;
}
.controls h2{
    color:white;
    font-size: 40px;
    margin:0;
    margin-top: 10px;
}
.color{
    width: 100px;
    height: 100px;
    border: 2px solid white;
    border-radius: 20px;
}
.colorCon{
    margin-top:40px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
}
.canvasTouch{
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 99;
}
.sizeClass{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-around;
    margin-top: 20px
}
.size{
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}
.sizeBall{
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.size > div > div{
    border-radius: 50%;

}
.small{
    width: 30px;
    height: 30px;
}
.medium{
    width: 50px;
    height: 50px;
}
.large{
    width: 70px;
    height: 70px;
}
.border{
    border: 4px solid white;
}
.mouseCircle{
    position: absolute;
    z-index: 98;
    border-radius: 50%;
}
.removeControls{
    display: flex;
    align-items: center;
    margin-top:60px;
    justify-content: space-around;
    width: 100%;
}
svg{
    height: 60px;
    width: 60px;
    border: 2px solid white;
    padding: 10px;
    border-radius: 50%;
}
</style>