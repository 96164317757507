<template>
    <div class="flip-card" @click="turnCard" :class="notfound?'':'notFound'">
        <div class="flip-card-inner" :class="turn?'turncard':''">
            <div class="flip-card-front"  :style="'background:'+color">
                <img :src="card.img" alt="" style="width: 200px;height: 300px;object-fit:cover">
            </div>
            <div class="flip-card-back" :style="'background:'+color">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MemoryCards",
    props:["color", "card", "active", "winSel", "restart"],
    data(){
        return{
            turn: false,
            notfound: true,
            start: false,
        }
    },
    watch:{
        winSel: function(newVal){
            setTimeout(function(){
                if(newVal == this.card){
                    this.notfound = false
                }else{
                    this.turn = true;
                }

            }.bind(this),600)
        },
        restart: function(newVal, oldVal){
            if(newVal == 0 && oldVal == 2){
                this.turn = false,
                this.start = false,
                this.notfound = true
                setTimeout(function(){
                    this.turn = true;
                    this.start = true;
                }.bind(this), 5000)
            }
        }
    },
    methods:{
       turnCard(){
        if(this.start == true){
            this.turn = false
        }
       }
    },
    created(){
        setTimeout(function(){
            this.turn = true;
            this.start = true;
        }.bind(this), 5000)
    }
}
</script>

<style scoped>
.flip-card {
  width: 200px;
  height: 300px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.4s;
  transform-style: preserve-3d;
}

.turncard {
  transform: rotateY(180deg) scale(0.85);
}
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  box-shadow: 0 5px 10px 0 grey;
}
.flip-card-back {
  transform: rotateY(180deg);
}
.notFound{
    opacity: 0;
}
</style>