<template>
    <div>
        <div class="memoryContainer" :style="'grid-template-columns:' + gridRows">
            <memory-card v-for="(card,index) in shuffledCards" :key="card" :card="card" class="memoryCard" @click="openCard(card);" :color="bgColor(index)" :winSel="secSel" :restart="start">
            </memory-card>
        </div>
        <game-won :gameOver="gameOver" @restart="restartGame" @goBack="goBack"></game-won>
    </div>
</template>

<script>
import MemoryCard from './MemoryCard.vue';
import GameWon from './GameWon.vue';

export default {
    name: "MemoryGame",
    props: ["cards"],
    components:{
        MemoryCard,
        GameWon
    },
    data(){
        return{
            curCards:[],
            turn: false,
            firstSel: false,
            countCards: 0,
            secSel: {
                id:0,
                img:""
            },
            gameOver: false,
            title:false,
            start: 0,
            refreshKey: 0,
        }
    },
    watch:{
        countCards: function(newVal, oldVal){
            if(newVal == 0){
                this.start = 2;
                this.gameOver = true;
            }
        }
    },
    computed:{
        shuffledCards(){
            this.refreshKey;
            let board = [];
            this.curCards = JSON.parse(JSON.stringify(this.cards));
            while (this.curCards.length > 0) {
                let card = Math.floor(Math.random() * this.curCards.length);
                if (board.includes(this.curCards[card])) {
                    board.push(this.curCards[card]);
                    this.curCards.splice(card, 1);
                } else {
                    board.push(this.curCards[card]);
                }
            }
            return board
        },
        gridRows(){
            return "1fr ".repeat(this.shuffledCards.length/2)
        }
    },
    methods:{
        openCard(card){
            if(this.start == 1){
                if(!this.firstSel){
                    this.firstSel = card
                    return;
                }
                if(this.firstSel == card){
                    this.secSel = card;
                    this.countCards--
                }else{
                    let check = JSON.parse(JSON.stringify(card));
                    check.id = 99
                    this.secSel = check
                }
                this.firstSel = false;
            }
        },
        goBack(){
            this.$emit("goBack")
        },
        restartGame(){
            this.gameOver = false;
            this.countCards = this.cards.length;
            this.start = 0;
            this.refreshKey++;
            setTimeout(function(){
                this.start = 1;
            }.bind(this), 5000)
        },
        bgColor(i){
            if (i % 2 === 0 && i < this.shuffledCards.length/2){
                return "linear-gradient(to right top, #ea7f8b 50%, #e26667 50%)";
            }
            if(i > this.shuffledCards.length/2){
                if (i % 2 === 0){
                    return "linear-gradient(to right top, #a1dee2 50%, #68d2e0 50%)";
                }
                return "linear-gradient(to right top, #ea7f8b 50%, #e26667 50%)";
            }
            return "linear-gradient(to right top, #a1dee2 50%, #68d2e0 50%)" //#ea7f8b
        }
    },
    created(){
        this.countCards = this.cards.length;
        setTimeout(function(){
            this.start = 1;
        }.bind(this), 5000)
    }
}
</script>
<style scoped>


.memoryContainer{
    display: grid;
    gap:20px;
}
.memoryCard{
    transform: rotate3d();
    transition: 0.5s all ease;
}
.gameOver{
        position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    font-size: 40px;
    color: white;
    background: #7accdb;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 5px 5px 0 #7accdb;
    transition: 1s all ease;
    opacity: 0;
}
</style>