<template>
    <div class="gameOverCon" v-show="gameOver" :style="title?'transform:scale(1)':''">
        <div class="gameOver" >
            <img src="../assets/img/Aap.png" class="winAnimal monkey" :style="loaded?'top: -230px;':''">
            <img src="../assets/img/Panda.png" class="winAnimal panda" :style="loaded?'top: -150px;':''">
            <div class="goodJob">Goed gedaan je hebt</div>
            <div class="wonTitle">Gewonnen !</div>
            <div class="wonLine"></div>
            <div class="playButtons">
                <div @click="goHome">Kies een ander spel</div>
                <div @click="restartGame">Speel opnieuw</div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "GameWon",
    props:["gameOver"],
    data(){
        return{
            title: false,
            loaded: false,
        }
    },
    watch:{
        gameOver: function(newVal){
            if(newVal == true){
                setTimeout(function(){
                    this.title = true;
                    setTimeout(function(){
                        this.loaded = true;
                    }.bind(this),900)
                }.bind(this),600)
            }
        }
    },
    methods:{
        restartGame(){
            this.$emit("restart", true)
        },
        goHome(){
            this.$emit("goBack");
        }
    }
}
</script>

<style scoped>
.gameOverCon{
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #8F967F;
    transform:scale(0);
    transition: 1s all ease;
    width: 100vw;
    height: 100vh;
    z-index: 99999999999;
}
.gameOver{
    font-size: 40px;
    color: white;
    background: #56603F;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 5px 5px 0 #56603F;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    position: relative;

}
.goodJob{
    font-size: 25px;
    margin-top: 10px;
}
.wonTitle{
    margin-top: -30px;
    font-size: 100px;
}
.wonLine{
    width: 50%;
    height: 5px;
    background: white;
    border-radius: 10px;
    margin-top: -45px;
    margin-bottom:50px;
}
.playButtons{
    display: flex;
    gap:50px;
}
.playButtons > div{
    background: white;
    color: #56603F;
    padding: 15px 20px;
    font-size: 35px;
    border-radius: 20px;
    box-shadow: inset 7px 7px 10px 0 #a9a6a6;
}
.winAnimal{
    position: absolute;
    z-index: -1;
    transition: 1s all ease;
}
.monkey{
    left: 20px;
    top:0;
}
.panda{
    right: 20px;
    top: 0;
}
</style>