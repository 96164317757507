<template>
    <div class="bg">
        <div class="gameBlocks">
            <game-block v-for="(game, index) in games" :key="game.text" :text="game.text" :type="game.type" :color="game.color" :amount="games.length" :number="index" :logo="game.logo" :levels="game.levels"></game-block>
        </div>
    </div>
</template>

<script>
import GameBlock from './components/GameBlock.vue'

export default {
    name: 'App',
    components:{
        GameBlock
    },
    data(){
        return{
            games: [
                {
                    text: "Lukt het jou om alle kaartjes bij elkaar te vinden?",
                    type: "color",
                    color: "#5A5B8C",
                    logo: "./assets/img/Logo_kp.png",
                    levels: [
                        {
                            name: "Leeuw",
                            color: "#799893",
                            logo: "./assets/img/coloring/colored/tijger.png",
                            cards: 
                                {
                                    img: './assets/img/coloring/Outline_kleurplaten_1.png'
                                }
                            
                        },
                        {
                            name: "Aap",
                            color: "#799893",
                            logo: "./assets/img/coloring/colored/Aap.png",
                            cards: 
                                {
                                    img: './assets/img/coloring/Outline_kleurplaten_4.png'
                                }
                            
                        },
                        {
                            name: "Giraffe",
                            color: "#799893",
                            logo: "./assets/img/coloring/colored/Girraf.png",
                            cards: 
                                {
                                    img: './assets/img/coloring/Outline_kleurplaten_2.png'
                                }
                            
                        },
                        {
                            name: "Panda",
                            color: "#799893",
                            logo: "./assets/img/coloring/colored/Panda.png",
                            cards: 
                                {
                                    img: './assets/img/coloring/Outline_kleurplaten_3.png'
                                }
                            
                        }
                    ]
                },
                {
                    text: "Lukt het jou om alle kaartjes bij elkaar te vinden?",
                    type: "difference",
                    color: "#D1554B",
                    logo: "./assets/img/Logo_zzd.png",
                    levels: [
                        {
                            name: "Makkelijk",
                            color: "#4db249",
                            logo: "./assets/img/Artboard_6.png",
                            cards:[
                                    {
                                        img: './assets/img/difference/easy/Zoek_de_verschillen_2.1.png',
                                        difference: [9,11,19,23,29,35,38,42],
                                        index: 50
                                    },
                                    {
                                        img: './assets/img/difference/easy/Zoek_de_verschillen_2.2.png',
                                        index: 50
                                    }

                                ]
                            
                        },
                        {
                            name: "Moeilijk",
                            color: "#b24949",
                            logo: "./assets/img/Artboard_7.png",
                            cards:[
                                    {
                                        img: './assets/img/difference/hard/zoek_de_verschillen1_1.png',
                                        difference: [34,42,41,31,60,48,68],
                                        index: 100
                                    },
                                    {
                                        img: './assets/img/difference/hard/zoek_de_verschillen1_2.png',
                                        index: 100
                                    }

                                ]
                        }
                    ]
                },
                {
                    text: "Lukt het jou om alle kaartjes bij elkaar te vinden?",
                    type: "memory",
                    color: "#EBDE71",
                    logo: "./assets/img/Logo_mm.png",
                    levels: [
                        {
                            name: "Makkelijk",
                            color: "#4db249",
                            logo: "./assets/img/Artboard_6.png",
                            cards:[
                                {
                                    id: 1,
                                    img: "./assets/img/memory/Artboard_1.png"
                                },
                                {
                                    id: 2,
                                    img: "./assets/img/memory/Artboard_2.png"
                                },
                                {
                                    id: 3,
                                    img: "./assets/img/memory/Artboard_3.png"
                                },
                                {
                                    id: 4,
                                    img: "./assets/img/memory/Artboard_4.png"
                                }
                            ]
                        },
                        {
                            name: "Normaal",
                            color: "#e37937",
                            logo: "./assets/img/Artboard_3.png",
                            cards:[
                                {
                                    id: 1,
                                    img: "./assets/img/memory/Artboard_1.png"
                                },
                                {
                                    id: 2,
                                    img: "./assets/img/memory/Artboard_2.png"
                                },
                                {
                                    id: 3,
                                    img: "./assets/img/memory/Artboard_3.png"
                                },
                                {
                                    id: 4,
                                    img: "./assets/img/memory/Artboard_4.png"
                                },
                                {
                                    id: 5,
                                    img: "./assets/img/memory/Artboard_5.png"
                                },
                                {
                                    id: 6,
                                    img: "./assets/img/memory/Artboard_6.png"
                                }
                            ]
                        },
                        {
                            name: "Moeilijk",
                            color: "#b24949",
                            logo: "./assets/img/Artboard_7.png",
                            cards:[
                                {
                                    id: 1,
                                    img: "./assets/img/memory/Artboard_1.png"
                                },
                                {
                                    id: 2,
                                    img: "./assets/img/memory/Artboard_2.png"
                                },
                                {
                                    id: 3,
                                    img: "./assets/img/memory/Artboard_3.png"
                                },
                                {
                                    id: 4,
                                    img: "./assets/img/memory/Artboard_4.png"
                                },
                                {
                                    id: 5,
                                    img: "./assets/img/memory/Artboard_5.png"
                                },
                                {
                                    id: 6,
                                    img: "./assets/img/memory/Artboard_6.png"
                                },
                                {
                                    id: 7,
                                    img: "./assets/img/memory/Artboard_7.png"
                                },
                                {
                                    id: 8,
                                    img: "./assets/img/memory/Artboard_8.png"
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    }
}
</script>
<style>

html{
    
  overscroll-behavior-x: none;
}
</style>
<style scoped>


*{
    font-family: 'Graycliff';
    user-select: none;
}
.bg{
    background-image: url("./assets/img/Background.png");
    background-color: #EEEFE7;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
}
h1{
    font-size: 70px;
    color:white;
}
.gameBlocks{
    margin-top:-250px;
    display: flex;
    width:100vw;
    justify-content: space-around;
    height: 40%;
}
</style>